import Website_me from '../../../assets/images/Website_me.jpg';
import './index.scss';

const Picture = () => {

    return (
        <div className='picture-container'>
            <img className='solid-pic' src={Website_me} alt = "me"/>
        </div>
    )
}

export default Picture