import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import { useEffect, useState } from 'react';
import { faFrog } from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loaders';

const About = () => {
    
    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)

        return ()=>{
            clearTimeout(timeoutId)
        }
    }, [])


    return(
        <>
        <div className='container about-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                    letterClass={letterClass}
                        strArray={['A', 'b', 'o', 'u','t', ' ', 'm', 'e']}
                        idx={15}
                    />
                </h1>
                <p>Hello! My name is David Delblanc, I am 24 years old and I live in Lund. During my upbringing, I spent a lot of time in team sports such as: floorball, handball and ice hockey. This means I have a lot of experience with managing, responding and working with people in teams. My time in the Armed Forces has also contributed to a large extent towards this.</p>
                <p>Currently, I am enrolled in my third year of my education in Computer Science. I will be starting my major in Software Engineering this fall. Head on over to my contact page if you're interested in collaborating, hiring or just talking!</p>
                <p>And yes, I like frogs. Hence, the cube.</p>

            </div>
            <div className='stage-cube-cont'>
                <div className='cube-spinner'>
                    <div className='face1'>
                        <FontAwesomeIcon icon={faFrog} color='#0f8c14'/>
                    </div>
                    <div className='face2'>
                        <FontAwesomeIcon icon={faFrog} color='#0f8c14'/>
                    </div>
                    <div className='face3'>
                        <FontAwesomeIcon icon={faFrog} color='#0f8c14'/>
                    </div>
                    <div className='face4'>
                        <FontAwesomeIcon icon={faFrog} color='#0f8c14'/>
                    </div>
                    <div className='face5'>
                        <FontAwesomeIcon icon={faFrog} color='#0f8c14'/>
                    </div>
                    <div className='face6'>
                        <FontAwesomeIcon icon={faFrog} color='#0f8c14'/>
                    </div>
                </div>
            </div>
        </div>
        <Loader type="pacman" />
        </>
    )
}

export default About