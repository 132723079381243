import AnimatedLetters from '../AnimatedLetters';
import './index.scss';
import {Link} from 'react-router-dom';
import { useEffect, useState } from 'react';
import Picture from './Picture';
import Loader from 'react-loaders';

const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = [' ', 'D', 'a', 'v', 'i', 'd',' ', 'D','e', 'l', 'b', 'l', 'a', 'n', 'c,']
    const studentArray = ['a', ' ', 'C','o', 'm', 'p', 'u', 't', 'e', 'r', ' ', 'S', 'c', 'i', 'e', 'n', 'c', 'e',' ', 'S', 't', 'u', 'd', 'e', 'n', 't', ' ', 'a', 't', ' ', 'L', 'T', 'H']
    

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000)

        return ()=>{
            clearTimeout(timeoutId)
        }
    }, [])


    return(
        <>
        <div className="container home-page">
            <div className="text-zone">
                <h1>
                <span className={letterClass}>H</span>
                <span className={`${letterClass} _12`}>i!</span>
                <br/>
                <span className={`${letterClass} _13`}>I</span>
                <span className={`${letterClass} _14`}>'m</span>
                <AnimatedLetters letterClass={letterClass} 
                strArray={nameArray}
                idx={15}
                />
                <br/>
                <AnimatedLetters letterClass={letterClass} 
                strArray={studentArray}
                idx={30}
                />
                </h1>
                <h2>Java / JavaScript / CSS / Python / C++</h2>
                <Link to="/contact" className='flat-button'>CONTACT ME</Link>
            </div>
            <Picture />
        </div>
        <Loader type="pacman" />
        </>
    );
}

export default Home;